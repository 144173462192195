import dayjs from 'dayjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, AbstractControl, FormControl, ValidationErrors, ValidatorFn, FormGroup, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { MatChipInputEvent } from '@angular/material/chips';
import { Action, Store, select } from '@ngrx/store';
import { Client, Contact, DataLoadSettings } from '../../../models/Client';
import { SubmitClientAction } from '../../../redux/actions/client';
import { State, getCustomFieldsEntries, getCustomFieldsSchemas, getDashboardRole, getGSettingsGlobalSettingsModel, getInvoiceDataSources } from '../../../redux/reducers/index';
import { DayOfWeek, InvoiceFrequency, TimeUnit, TimeOfDay, TimeRounding, CustomProcessFrequencyType } from '../../../shared/enums';
import { ResponsiveService } from '../../../services/responsive.service';
import { environment } from 'environments/environment';
import { sortInvoiceDueDate } from '../../../shared/helpers';
import { take } from 'rxjs/operators';
import { CartwheelSelectOptions, SuccessStatus } from '@cartwheel/web-components';
import { Observable, combineLatest } from 'rxjs';
import { InvoiceDataSource } from 'app/models/invoice-data-source';
import { PATTERNS } from 'app/shared/consts';
import { UserSettings } from 'app/models/user-settings';
import { CustomFieldEntity, CustomFieldEntryForm, CustomFieldEntryModel, CustomFieldSchemaModel, UpsertCustomFieldEntryModel } from 'app/models/custom-fields';
import { CustomFieldActions } from 'app/redux/actions/customfields';

@Component({
  selector: 'app-add-enterprise-client',
  templateUrl: './add-enterprise-client.component.html',
  styleUrls: ['./add-enterprise-client.component.scss']
})
export class AddEnterpriseClientComponent implements OnInit {
  globalSettingsModel$: Observable<UserSettings>;
  globalSettingsModel = new UserSettings();
  customClientFieldsSchemas$: Observable<CustomFieldSchemaModel[]>;
  customClientFieldsSchemas: CustomFieldSchemaModel[] = [];
  customClientFieldsEntries$: Observable<CustomFieldEntryModel[]>;
  customClientFieldsEntries: CustomFieldEntryModel[] = [];

  public customClientFields: FormArray<FormGroup<CustomFieldEntryForm>> = new FormArray<FormGroup<CustomFieldEntryForm>>([]);

  newClient: Client;
  addClientForm: UntypedFormGroup;
  clientInfo: UntypedFormGroup;
  automationInfo: UntypedFormGroup;
  invoiceForm: UntypedFormGroup;
  selectedClient: Client;
  approvalForm: UntypedFormGroup;
  usersForm: UntypedFormGroup;
  showPrimaryContactForm: boolean = null;
  primaryContactForm: UntypedFormGroup;
  timesheetApprovalSettingsForm: UntypedFormGroup;
  timesheetReminderSettingsForm: UntypedFormGroup;
  allowTimesheetApprovals: boolean = true; // TODO make sure to set this
  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;
  thirdFormGroup: UntypedFormGroup;
  isLinear = true;
  componentFloat = false;
  env = environment;
  isCustomInvoice: boolean = false;
  shouldInitializeAddressLine: boolean = false;
  approval: boolean;
  isMobile: boolean;
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  addOnBlur = true;
  validateEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  validateDecimal = '[0-9]\\d*(\\.\\d+)?';
  successStatus = SuccessStatus;
  invoiceInterval = [
    {
      name: 'Days',
      value: 'Days'
    },
    {
      name: 'Weeks',
      value: 'Weeks'
    },
    {
      name: 'Months',
      value: 'Months'
    }
  ];
  reportDay: CartwheelSelectOptions<DayOfWeek> = [
    {
      label: 'Monday',
      value: DayOfWeek.Monday
    },
    {
      label: 'Tuesday',
      value: DayOfWeek.Tuesday
    },
    {
      label: 'Wednesday',
      value: DayOfWeek.Wednesday
    },
    {
      label: 'Thursday',
      value: DayOfWeek.Thursday
    },
    {
      label: 'Friday',
      value: DayOfWeek.Friday
    },
    {
      label: 'Saturday',
      value: DayOfWeek.Saturday
    },
    {
      label: 'Sunday',
      value: DayOfWeek.Sunday
    }
  ];
  billedRateOptions: CartwheelSelectOptions<string> = [
    {
      label: 'Task Hourly Rate',
      value: 'hourly'
    }
  ];
  timeRoundingOptions: CartwheelSelectOptions<TimeRounding> = [
    {
      label: 'Round to the nearest minute',
      value: TimeRounding.ToNearestMinute
    },
    {
      label: 'Round to the nearest quarter hour',
      value: TimeRounding.ToNearestQuarterHour
    },
    {
      label: 'Round to the nearest half hour',
      value: TimeRounding.ToNearestHalfHour
    },
    {
      label: 'Round to the nearest hour',
      value: TimeRounding.ToNearestHour
    },
    {
      label: 'Round up to the nearest minute',
      value: TimeRounding.UpToNearestMinute
    },
    {
      label: 'Round up to the nearest quarter hour',
      value: TimeRounding.UpToNearestQuarterHour
    },
    {
      label: 'Round up to the nearest half hour',
      value: TimeRounding.UpToNearestHalfHour
    },
    {
      label: 'Round up to the nearest hour',
      value: TimeRounding.UpToNearestHour
    },
    {
      label: 'Round down to the nearest minute',
      value: TimeRounding.DownToNearestMinute
    },
    {
      label: 'Round down to the nearest quarter hour',
      value: TimeRounding.DownToNearestQuarterHour
    },
    {
      label: 'Round down to the nearest half hour',
      value: TimeRounding.DownToNearestHalfHour
    },
    {
      label: 'Round down to the nearest hour',
      value: TimeRounding.DownToNearestHour
    }
  ];
  timeOfDay: CartwheelSelectOptions<TimeOfDay> = [
    {
      label: '7 am',
      value: TimeOfDay.SevenAM
    },
    {
      label: '8 am',
      value: TimeOfDay.EightAM
    },
    {
      label: '9 am',
      value: TimeOfDay.NineAM
    },
    {
      label: '10 am',
      value: TimeOfDay.TenAM
    },
    {
      label: '2 pm',
      value: TimeOfDay.TwoPM
    },
    {
      label: '4 pm',
      value: TimeOfDay.FourPM
    }
  ];
  invoiceDueInterval: CartwheelSelectOptions<number> = [];
  invoiceFrequencies: CartwheelSelectOptions<InvoiceFrequency> = [
    {
      label: 'Bi-weekly on the 1st and 15th',
      value: InvoiceFrequency.BiWeeklyOnTheFirstAndFifteenth
    },
    {
      label: 'At the end of the month',
      value: InvoiceFrequency.AtTheEndOfTheMonth
    },
    {
      label: 'Every Friday',
      value: InvoiceFrequency.EveryFriday
    },
    {
      label: 'Custom',
      value: InvoiceFrequency.Custom
    }
  ];
  customInvoiceInterval: CartwheelSelectOptions<TimeUnit> = [
    {
      label: 'Month',
      value: TimeUnit.Month
    },
    {
      label: 'Week',
      value: TimeUnit.Week
    },
    {
      label: 'Day',
      value: TimeUnit.Day
    }
  ]

  reminderFrequencyTypes: CartwheelSelectOptions<CustomProcessFrequencyType> = [
    {
      label: 'Months',
      value: CustomProcessFrequencyType.Months,
    },
    {
      label: 'Weeks',
      value: CustomProcessFrequencyType.Weeks,
    },
    {
      label: 'Days (minimum: 3)',
      value: CustomProcessFrequencyType.Days,
    }
  ];

  private action: Action;

  constructor(
    private fb: UntypedFormBuilder,
    public dialog: MatDialogRef<AddEnterpriseClientComponent>,
    private destroyRef: DestroyRef,
    private responsiveService: ResponsiveService,
    private store: Store<State>,
    @Inject(MAT_DIALOG_DATA) data: any,
  ) {
    this.action = data;
    this.invoiceDueInterval = sortInvoiceDueDate();
  }

  ngOnInit() {
    this.globalSettingsModel$ = this.store.select(getGSettingsGlobalSettingsModel);
    this.globalSettingsModel$.pipe(
      takeUntilDestroyed(this.destroyRef))
      .subscribe((gModel: UserSettings) => {
        this.globalSettingsModel = new UserSettings(gModel);
        this.allowTimesheetApprovals = this.globalSettingsModel.companySettings?.allowTimesheetApprovals ?? true; 
        this.showPrimaryContactForm = this.globalSettingsModel.companySettings?.clientsCanHavePrimaryContacts;
      });

    this.approvalForm = new UntypedFormGroup({
      approvers: new UntypedFormArray([])
    });

    this.responsiveService
      .getMobileStatus()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((status) => {
        this.isMobile = status;
      });

    this.clientInfo = new UntypedFormGroup({
      clientName: new UntypedFormControl(null, Validators.required),
      timeRounding: new UntypedFormControl(TimeRounding.ToNearestMinute, Validators.required),
      billedRate: new UntypedFormControl(0, [Validators.required, Validators.pattern(this.validateDecimal)]),
      billedOn: new UntypedFormControl('hourly'),
      address1: new FormControl<string>(null),
      address2: new FormControl<string>(null),
    });

    this.primaryContactForm = new FormGroup({
      firstName: new FormControl<string>(null, Validators.required),
      lastName: new FormControl<string>(null, Validators.required),
      email: new FormControl<string>(null, [Validators.required, Validators.email]),
    });

    this.primaryContactForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((s: Contact) => {
        if ((s.email || s.firstName || s.lastName) && !this.primaryContactForm.controls.firstName.hasValidator(Validators.required)) {
          ['firstName', 'lastName', 'email'].map(s => {
            this.primaryContactForm.controls[s].addValidators(Validators.required);
            this.primaryContactForm.controls[s].updateValueAndValidity({ emitEvent: false });
          });
        } else if (!s.email && !s.firstName && !s.lastName) {
          ['firstName', 'lastName', 'email'].map(s => {
            this.primaryContactForm.controls[s].removeValidators(Validators.required);
            this.primaryContactForm.controls[s].updateValueAndValidity({ emitEvent: false });
          });
        }
      });


    this.invoiceForm = new UntypedFormGroup({
      reportDay: new UntypedFormControl(DayOfWeek.Monday, Validators.required),
      timeOfDay: new UntypedFormControl(TimeOfDay.NineAM, Validators.required),
      invoiceFrequency: new UntypedFormControl(InvoiceFrequency.BiWeeklyOnTheFirstAndFifteenth, Validators.required),
      sendClientInvoice: new UntypedFormControl(false),
      invoiceDueInterval: new UntypedFormControl(),
      invoiceTerms: new UntypedFormControl(),
      invoicePrefix: new UntypedFormControl(),
      customInvoiceStartAt: new UntypedFormControl(dayjs().toDate()),
      customInvoiceIntervalUnit: new UntypedFormControl(TimeUnit.Week),
      customInvoiceInterval: new UntypedFormControl(1, [Validators.pattern(PATTERNS.Number)])
    });

    this.timesheetApprovalSettingsForm = new UntypedFormGroup({
      sendReminders: new FormControl<boolean>(null)
    });

    this.primaryContactForm = this._initPrimaryContactFormFields();

    if (this.allowTimesheetApprovals) {
      this.timesheetReminderSettingsForm = new UntypedFormGroup({
        reminderFrequencyType: new FormControl<CustomProcessFrequencyType>(null),
        interval: new FormControl<number>(null),
      });

      this.timesheetReminderSettingsForm.setValidators(this._reminderIntervalValidator());
    }

    this.invoiceForm.get('invoiceFrequency').valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(invoiceFrequency => {
        this.isCustomInvoice = Number(invoiceFrequency) === InvoiceFrequency.Custom;

        if (this.isCustomInvoice) {
          this.invoiceForm.controls.customInvoiceInterval.setValidators([Validators.required, Validators.pattern(PATTERNS.Number)]);
          this.invoiceForm.updateValueAndValidity({ emitEvent: false });
        } else {
          this.invoiceForm.controls.customInvoiceInterval.setValidators([]);
          this.invoiceForm.updateValueAndValidity({ emitEvent: false });
        }
      });

    const sendClientInvoiceControl = this.invoiceForm.get('sendClientInvoice');
    sendClientInvoiceControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((sendClientInvoices: boolean) => {
        if (sendClientInvoices) {
          this.invoiceForm.setControl('invoiceEmails', new UntypedFormControl([], [Validators.required]));
          this.invoiceForm
            .get('invoiceEmails')
            .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((emails: string[]) => {
              const invalidEmails = emails.filter((e) => !this.validateEmail.test(e.trim()));
              if (invalidEmails.length) {
                this.invoiceForm.get('invoiceEmails').setErrors({
                  email: true
                });
              }
            });
        } else {
          this.invoiceForm.removeControl('invoiceEmails');
        }
      });

    if (this.env.feature.invoicing.allowUserInvoice) {
      this.invoiceForm.addControl('sendUserInvoice', new UntypedFormControl(false));
      // needed to trigger logic to set invoice emails
      sendClientInvoiceControl.setValue(sendClientInvoiceControl.value);

      const sendUserInvoiceControl = this.invoiceForm.get('sendUserInvoice');
      sendUserInvoiceControl.valueChanges
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((sendClientInvoices: boolean) => {
          if (sendClientInvoices) {
            this.invoiceForm.setControl('userInvoiceEmails', new UntypedFormControl([], [Validators.required]));
            this.invoiceForm.setControl('userInvoiceEmail', new UntypedFormControl(null));
          } else {
            this.invoiceForm.removeControl('userInvoiceEmails');
            this.invoiceForm.removeControl('userInvoiceEmail');
          }
        });

      sendUserInvoiceControl.setValue(sendUserInvoiceControl.value);
    }

    this.dialog.afterClosed().subscribe((hidden) => {
      if (this.action) {
        this.store.dispatch(this.action);
      }
    });

    this.store.dispatch(CustomFieldActions.getCustomFieldSchemasByEntity({ entity: CustomFieldEntity.Client }));
    this.store.select(getCustomFieldsSchemas)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((schemas: CustomFieldSchemaModel[]) => {
        this.customClientFieldsSchemas = schemas.filter(schema => schema.customFieldEntity === CustomFieldEntity.Client);
        this.customClientFields = this._initCustomFields(this.customClientFieldsSchemas);
      });
    this.store.select(getCustomFieldsEntries)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((entries) => {
        this.customClientFieldsEntries = entries;
      });
  }

  onNext(stepper: MatStepper) {
    if (stepper.selectedIndex === 0) {
      this._markFormGroupTouched(this.clientInfo);
    }
    stepper.next();
  }

  public onPrevious(stepper: MatStepper) {
    stepper.previous();
  }

  public add(event: MatChipInputEvent, invoiceEmails: AbstractControl): void {
    const { chipInput, value } = event;
    const currentEmailControl = new UntypedFormControl(value, [Validators.required, Validators.email]);
    if ((value || '').trim() && !currentEmailControl.errors) {
      const existingInvoiceEmails: string[] = invoiceEmails.value.concat(value.trim());
      invoiceEmails.setValue(existingInvoiceEmails);
      invoiceEmails.markAsDirty();
      chipInput.clear();
    }
  }

  public closeDialog() {
    this.dialog.close();
  }

  public handleAddressChange({ address1, address2 }: { address1: string; address2: string }): void {
    this.clientInfo.get('address1').setValue(address1);
    this.clientInfo.get('address2').setValue(address2);

    this.shouldInitializeAddressLine = false;
    this.clientInfo.markAsDirty();
  }

  public remove(index: number, invoiceEmails: AbstractControl): void {
    if (index >= 0) {
      const existingInvoiceEmails = [...invoiceEmails.value.slice(0, index), ...invoiceEmails.value.slice(index + 1)];
      invoiceEmails.setValue(existingInvoiceEmails);
      invoiceEmails.markAsDirty();
    }
  }

  public saveClient(): void {
    this.newClient = new Client();

    this.newClient = {...this.newClient, ...this.approvalForm.value};
    if (this.usersForm.get('assignedUsers')?.value) {
      this.newClient = {
        ...this.newClient,
        assignedUsers: this.usersForm.get('assignedUsers').value
      };
    }
    this.newClient = {
      ...this.newClient,
      ...this.clientInfo.value,
      ...this.invoiceForm.value,
      creationDateTime: new Date()
    };

    if (this.invoiceForm.get('invoiceFrequency')?.value === InvoiceFrequency.Custom) {
      this.newClient = {
        ...this.newClient,
        customInvoiceFrequency: {
          interval: this.invoiceForm.get('customInvoiceInterval').value ?? 1,
          frequencyType: this.invoiceForm.get('customInvoiceIntervalUnit').value ?? TimeUnit.Week,
          invoiceStart: this.invoiceForm.get('customInvoiceStartAt').value ?? dayjs().toDate()
        }
      }
    }

    const sendReminders = this.timesheetApprovalSettingsForm.get('sendReminders').value;
    if (sendReminders) {
      this.newClient = {
        ...this.newClient,
        clientSettings: {
          ...this.newClient.clientSettings,
          timesheetApprovalSettings: {
            sendReminders
          }
        }
      }
    }

    if (this.primaryContactForm.get('firstName')?.value &&
      this.primaryContactForm.get('lastName')?.value &&
      this.primaryContactForm.get('email')?.value) {
      this.newClient = {
        ...this.newClient,
        primaryContact: {
          ...this.primaryContactForm.value
        }
      }
    }

    if (this.allowTimesheetApprovals) {
      const reminderFrequencyType =  this.timesheetReminderSettingsForm.get('reminderFrequencyType').value;
      const interval = this.timesheetReminderSettingsForm.get('interval').value;

      const reminderFrequencyTypeIsNull = reminderFrequencyType === null;
      const intervalIsNull = interval === null;

      if (!reminderFrequencyTypeIsNull && !intervalIsNull) {
        this.newClient = {
          ...this.newClient,
          clientSettings: {
            ...this.newClient.clientSettings,
            timesheetReminderSettings: {
              interval,
              reminderFrequencyType,
            }
          }
        }
      }
    }

    combineLatest([
      this.store.pipe(select(getDashboardRole)),
      this.store.pipe(select(getGSettingsGlobalSettingsModel)),
      this.store.pipe(select(getInvoiceDataSources))
    ])
      .pipe(take(1))
      .subscribe(([role, globalSettings, invoiceDataSources]) => {
        if (globalSettings.companySettings?.defaultClientSettings?.dataLoadSettings) {
          this.newClient = {
            ...this.newClient,
            clientSettings: globalSettings.companySettings.defaultClientSettings
          };
        } else if (Object.values(invoiceDataSources).some((s: InvoiceDataSource) => !!s.connectionStatus)) {
          this.newClient = {
            ...this.newClient,
            clientSettings: {
              dataLoadSettings: new DataLoadSettings({
                processDayOfWeek: DayOfWeek.Friday,
                processFrequency: {
                  interval: 1,
                  frequencyType: TimeUnit.Week,
                  processStart: new Date()
                },
                lookbackFrequency: {
                  interval: 1,
                  frequencyType: TimeUnit.Week,
                  processStart: new Date()
                },
              }),
            }
          };
        }
        this.shouldInitializeAddressLine = true;

        this.store.dispatch(new SubmitClientAction({client: this.newClient, customFields: this.getNewCustomClientFieldsEntries('tempId'), role }));
      });
  }

  public updateForm(userForm: UntypedFormGroup) {
    this.usersForm = userForm;
  }

  public updateApprovalForm(approvalForm: UntypedFormGroup) {
    this.approvalForm = approvalForm;
  }

  public getNewCustomClientFieldsEntries(clientId: string): { entryToAdd: UpsertCustomFieldEntryModel, originalEntry: CustomFieldEntryModel }[] {
    const previousEntriesMap = new Map(this.customClientFieldsEntries
      .filter(entry => entry.entityId === clientId)
      .map(entry => [entry.fieldName, entry])
    );
    const schemaMap = new Map(this.customClientFieldsSchemas.map(schema => [schema.fieldName, schema])); 

    return this.customClientFields.controls
      .flatMap(formGroup =>
        Object.entries(formGroup.controls)
          .filter(([fieldName, current]) => schemaMap.get(fieldName)?.defaultFieldValue !== current.value)
          .map(([fieldName, current]) => {
            return {
              entryToAdd: {
                customFieldEntity: CustomFieldEntity.Client,
                entityId: clientId,
                fieldName,
                fieldValue: current.value,
              },
              originalEntry: previousEntriesMap.get(fieldName),
            };
          })
      );
  }

  private _initCustomFields(schemas: CustomFieldSchemaModel[]): FormArray<FormGroup<CustomFieldEntryForm>> {
    const customFields = schemas.map(schema => 
      new FormGroup<CustomFieldEntryForm>({
        [schema.fieldName]: new FormControl<string | null>(schema.defaultFieldValue || null)
      })
    );
  
    return new FormArray<FormGroup<CustomFieldEntryForm>>(customFields);
  }

  private _initPrimaryContactFormFields(): UntypedFormGroup {
    const form = this.fb.group({
      email: [this.selectedClient?.primaryContact?.email || null, [Validators.email]],
      firstName: [this.selectedClient?.primaryContact?.firstName || null],
      lastName: [this.selectedClient?.primaryContact?.lastName || null],
    });

    form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((s: Contact) => {
        if ((s.email || s.firstName || s.lastName) && !form.controls.firstName.hasValidator(Validators.required)) {
          ['firstName', 'lastName', 'email'].map(s => {
            form.controls[s].addValidators(Validators.required);
            form.controls[s].updateValueAndValidity({ emitEvent: false });
          });
        } else if (!s.email && !s.firstName && !s.lastName) {
          ['firstName', 'lastName', 'email'].map(s => {
            form.controls[s].removeValidators(Validators.required);
            form.controls[s].updateValueAndValidity({ emitEvent: false });
          });
        }
      });
    return form;
  }

  // Marks all controls in a form group as touched
  private _markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control.controls) {
        this._markFormGroupTouched(control);
      }
    });
  }

  private _reminderIntervalValidator(): ValidatorFn {
    return (group: UntypedFormGroup): ValidationErrors => {
      const intervalControl = group.controls['interval'];
      const typeControl = group.controls['reminderFrequencyType'];

      const isIntervalNull = intervalControl.value === null;
      const isTypeNull = typeControl.value === null;

      if(!isIntervalNull && isTypeNull) {
        typeControl.setErrors({pair: 'Unable to save without interval value'})
      } else if (isIntervalNull && !isTypeNull) {
        intervalControl.setErrors({pair: 'Unable to save without type value'})
      } else if(!isIntervalNull && !isTypeNull) {     
        if(typeControl.value === 0 && intervalControl.value < 3) {
          intervalControl.setErrors({min: 3})
        } else if (intervalControl.value < 1) {
          intervalControl.setErrors({min: 1})
        } else {
          intervalControl.setErrors(null)
        }
      } else {
        intervalControl.setErrors(null)
      }
      return;
    }
  };
}
